import React, { useState, useEffect } from "react";
import styles from "./recipe_card.module.css";
import "./animation.css";
import cx from "classnames";
import TouchWraper from "../touch_wraper";
import TButton from "../t_button";
import { BUTTON_TYPES } from "../t_button/t_button";

type CardData = {
  id: number;
  name: string;
  image: string;
  video: string;
};

interface Props {
  onCardClicked: () => void;
  overviewClosed?: boolean;
  data: CardData;
}

const RecipeCard = ({ onCardClicked, overviewClosed = true, data }: Props) => {
  const fakeImageRef = React.createRef<HTMLImageElement>();
  const imageRef = React.createRef<HTMLImageElement>();
  const [didAnimate, setDidAnimate] = useState(false);
  const animDuration = 250;
  const [playZ, setPlayZ] = useState(overviewClosed);
  const videoId = `recipe-video-${data.id}`;
  const videoOverLayId = videoId + "-overlay";

  let videoPlayer: HTMLVideoElement | null = null;

  const startEnterAnimation = () => {
    const fakeImage = fakeImageRef.current;
    const image = imageRef.current;
    if (fakeImage && image) {
      const rect = image.getBoundingClientRect();
      fakeImage.style.display = "block";
      fakeImage.style.left = `${rect.left}px`;
      fakeImage.style.top = `${rect.top}px`;
      fakeImage.style.width = `${rect.width}px`;
      image.style.visibility = "hidden";
      //fakeImage.style.transition = `left ${animDuration}ms, transform ${animDuration}ms, top ${animDuration}ms, width ${animDuration}ms`
      fakeImage.style.transition = `all ${animDuration}ms`;
      onCardClicked();
      setTimeout(() => {
        fakeImage.classList.add("overview-trans");
        setTimeout(() => {
          fakeImage.style.visibility = "hidden";
        }, animDuration);
        setDidAnimate(true);
      }, 50);
    }
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (overviewClosed && didAnimate) {
      const fakeImage = fakeImageRef.current;
      const image = imageRef.current;
      if (fakeImage && image) {
        fakeImage.style.visibility = "visible";
        fakeImage.classList.remove("overview-trans");
        timeout = setTimeout(() => {
          fakeImage.style.display = "none";
          image.style.visibility = "visible";
          setDidAnimate(false);
        }, animDuration);
      }
    }
    return () => timeout && clearTimeout(timeout);
  }, [didAnimate, fakeImageRef, imageRef, overviewClosed]);

  const cardClicked = () => {
    startEnterAnimation();
    setPlayZ(false);
  };

  const playVideo = () => {
    const video = document.getElementById(videoId);
    const overlay = document.getElementById(videoOverLayId);
    if (video) {
      video.classList.remove(styles.videoHidden);
      video.classList.toggle(styles.videoShow);
    }
    if (overlay) overlay.classList.toggle(styles.videoOverlay_show);
    if (videoPlayer) {
      videoPlayer.play();
    }
    setPlayZ(false);
  };

  const onVideoEnd = () => {
    const video = document.getElementById(videoId);
    const overlay = document.getElementById(videoOverLayId);
    if (video) {
      video.classList.remove(styles.videoShow);
      video.classList.toggle(styles.videoHidden);
    }
    if (overlay) overlay.classList.remove(styles.videoOverlay_show);
    setPlayZ(true);
  };
  return (
    <div className={styles.li}>
      <TouchWraper onClick={cardClicked}>
        <div className={styles.container}>
          <div className={styles.imageContainer}>
            <img
              ref={imageRef}
              alt=""
              className={styles.img}
              src={data.image}
            />
            <img
              ref={fakeImageRef}
              alt=""
              className={cx(styles["fake-img"], styles["img"])}
              src={data.image}
            />
          </div>

          <div id={videoOverLayId} className={styles.videoOverlay}></div>

          <div className={styles.videoContainer}>
            <video
              ref={(v) => {
                videoPlayer = v;
              }}
              id={videoId}
              className={[styles.video, styles.videoHidden].join(" ")}
              onEnded={onVideoEnd}
              playsInline={true}
              src={data.video}
            ></video>
          </div>

          <div className={styles.bottom}>
            <h2 className={styles.name}>{data.name}</h2>
          </div>
          <div
            className={[
              styles.playButtonContainer,
              playZ ? styles.playZ : "",
            ].join(" ")}
          >
            <TButton onClick={playVideo} type={BUTTON_TYPES.action_play} />
          </div>
        </div>
      </TouchWraper>
    </div>
  );
};

export default RecipeCard;
