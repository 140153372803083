import fetch from "cross-fetch";
import config from "../config";

const TIMESTAMP_KEY = "timestamp_key";
const DATA_KEY = "data_key";

enum FETCH_SETTING {
  DATABASE = "db",
  JSON = "json",
}

const fetchNew = async (new_data_timestamp: string) => {
  const new_data = await (await fetch(config.RECIPE_URL_FILE)).json();
  localStorage.setItem(DATA_KEY, JSON.stringify(new_data));
  localStorage.setItem(TIMESTAMP_KEY, new_data_timestamp);
  return new_data;
};

const getData = async () => {
  try {
    if (process.env.REACT_APP_FETCH_SETTING === FETCH_SETTING.DATABASE) {
      const res = await fetch(config.RECIPE_URL);
      if (!res) return [];
      return res.json();
    }

    const new_data_timestamp = await (
      await fetch(config.DATA_TIMESTAMP_URL)
    ).text();

    const cur_data_timestamp = localStorage.getItem(TIMESTAMP_KEY);
    const old_data = localStorage.getItem(DATA_KEY);

    if (
      !cur_data_timestamp ||
      parseFloat(cur_data_timestamp) < parseFloat(new_data_timestamp) ||
      !old_data ||
      old_data.length < 100
    ) {
      return await fetchNew(new_data_timestamp);
    }
    return JSON.parse(old_data);
  } catch (error) {
    console.error(error);
    return [];
  }
};

export default getData;
