import React, { useEffect, useRef, useState } from "react";
import styles from "./shopping_ingredient_card.module.css";
import { RecipeIngredient } from "../../model";
import check from "../../../assets/new_ui/check@x1.png";
import check_2x from "../../../assets/new_ui/check@x2.png";
import check_3x from "../../../assets/new_ui/check@x3.png";
import rosaliten from "../../../assets/new_ui/rosaliten.png";
import rosaliten_2x from "../../../assets/new_ui/rosaliten@2x.png";
import rosaliten_3x from "../../../assets/new_ui/rosaliten@3x.png";
import PlayButton from "../play_button";
import { PlayButtonTypes } from "../play_button/play_button";

interface Props {
  ingredient: RecipeIngredient;
  onCardClick: (id: number) => void;
}

const ShoppingIngredientCard = ({ ingredient, onCardClick }: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [checked, setChecked] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (!videoRef.current) return;
    const vidRef = videoRef.current;
    vidRef.addEventListener("ended", videoEnded);
    vidRef.addEventListener("pause", videoPaused);

    return () => {
      vidRef.removeEventListener("ended", videoEnded);
      vidRef.removeEventListener("pause", videoPaused);
    };
  }, []);

  const videoPaused = () => {
    setIsPlaying(false);
  };

  const videoEnded = () => {
    setIsPlaying(false);
  };

  const onClick = (id: number) => {
    setChecked(!checked);
    onCardClick(id);
  };

  const getSubtitle = (ingredient: RecipeIngredient) => {
    let subtitle = `${ingredient.quantity_shop} `;
    if (ingredient.quantity_shop > 1) {
      subtitle += ingredient.measurement_shop.full_name_plural;
    } else {
      subtitle += ingredient.measurement_shop.full_name;
    }
    return subtitle;
  };

  const playVideo = () => {
    if (!videoRef.current) return;

    setIsPlaying(true);
    videoRef.current.currentTime = 0;
    videoRef.current.play();
  };

  const catColor = ingredient.ingredient.category.color;
  return (
    <li
      className={[styles.container, checked ? styles.checked : ""].join(" ")}
      style={{
        borderColor: catColor,
        backgroundColor: checked ? "white" : catColor,
      }}
    >
      <div
        className={styles.imageWrapper}
        style={{ backgroundColor: ingredient.ingredient.category.color }}
      >
        <video
          ref={videoRef}
          playsInline
          preload="auto"
          className={styles.video}
          src={ingredient.video.file}
          style={isPlaying ? { display: "block" } : undefined}
        />
        <div className={styles.containerPoster}>
          <img
            className={styles.poster}
            src={rosaliten}
            srcSet={`${rosaliten} 1x, ${rosaliten_2x} 2x, ${rosaliten_3x} 3x`}
            alt="rosaliten"
          />
          <div className={styles.playButtonContainer}>
            <PlayButton tiny type={PlayButtonTypes.play} onClick={playVideo} />
          </div>
        </div>
        <img
          className={styles.image}
          src={ingredient.ingredient.image}
          alt={ingredient.ingredient.name}
          onClick={() => onClick(ingredient.id)}
        />
      </div>
      <div
        className={styles.textWrapper}
        onClick={() => onClick(ingredient.id)}
      >
        <h2>{ingredient.ingredient.name}</h2>
        <h3>{getSubtitle(ingredient)}</h3>
      </div>
      {checked && (
        <img
          className={styles.checkbox}
          src={check}
          srcSet={`${check} 1x, ${check_2x} 2x, ${check_3x} 3x`}
          alt="check"
        />
      )}
    </li>
  );
};

export default ShoppingIngredientCard;
