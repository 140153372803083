import React, { useState } from "react";
import { Accessory } from "../../model";
import styles from "./accessory_card_two.module.css";
import TouchWraper from "../touch_wraper";
import TButton from "../t_button";
import { BUTTON_TYPES } from "../t_button/t_button";
import check from "../../../assets/new_ui/check@x1.png";
import check_2x from "../../../assets/new_ui/check@x2.png";
import check_3x from "../../../assets/new_ui/check@x3.png";
import questionmark_1x from "../../../assets/new_ui/quest@x1.png";
import questionmark_2x from "../../../assets/new_ui/quest@x2.png";
import questionmark_3x from "../../../assets/new_ui/quest@x3.png";
import { ARIA_ROLES } from "../touch_wraper/roles";

interface Props {
  accessory: Accessory;
  onClick: (accessory: Accessory) => void;
  checked: boolean;
  tool?: boolean;
}

const AccessoryCard = ({ accessory, onClick, checked, tool }: Props) => {
  const [playZ, setPlayZ] = useState(true);
  const videoId = `recipe-video-${accessory.id}`;
  const videoOverLayId = videoId + "-overlay";

  let videoPlayer: HTMLVideoElement | null = null;

  const cardClicked = () => {
    onClick(accessory);
  };

  const playVideo = () => {
    const video = document.getElementById(videoId);
    const overlay = document.getElementById(videoOverLayId);
    if (video) {
      video.classList.remove(styles.videoHidden);
      video.classList.toggle(styles.videoShow);
    }
    if (overlay) overlay.classList.toggle(styles.videoOverlay_show);
    if (videoPlayer) {
      videoPlayer.play();
    }
    setPlayZ(false);
  };

  const onVideoEnd = () => {
    const video = document.getElementById(videoId);
    const overlay = document.getElementById(videoOverLayId);
    if (video) {
      video.classList.remove(styles.videoShow);
      video.classList.toggle(styles.videoHidden);
    }
    if (overlay) overlay.classList.remove(styles.videoOverlay_show);
    setPlayZ(true);
  };

  const calSize = () => {
    const width = window.innerWidth - 20 < 300 ? window.innerWidth - 20 : 292;
    const height = (width * 9) / 16;
    return {
      width: width,
      height: height,
    };
  };

  const getImageContainerStyle = () => {
    const size = calSize();
    return {
      backgroundColor: checked ? undefined : accessory.backgroundColor,
      height: size.height,
      width: size.width,
    };
  };

  return (
    <div className={styles.li}>
      <TouchWraper
        role={ARIA_ROLES.checkbox}
        ariaLabel={accessory.title}
        tabIndex={0}
        onClick={cardClicked}
      >
        <div className={styles.container}>
          <div
            className={[
              styles.checkContainer,
              checked ? styles.checked : styles.notChecked,
            ].join(" ")}
          >
            {checked ? (
              <img
                src={check}
                srcSet={`${check} 1x, ${check_2x} 2x, ${check_3x} 3x`}
                alt="check"
              />
            ) : (
              <img
                src={questionmark_2x}
                srcSet={`${questionmark_1x} 1x, ${questionmark_2x} 2x, ${questionmark_3x} 3x`}
                alt="question mark"
              />
            )}
          </div>
          <div
            style={getImageContainerStyle()}
            className={[
              styles.imageContainer,
              tool && !checked && styles.imageContainerBackground,
            ].join(" ")}
          >
            {accessory.image && (
              <img
                alt={accessory.title}
                className={[
                  styles.img,
                  tool && checked && styles.grayscale,
                ].join(" ")}
                src={accessory.image}
              />
            )}
          </div>
          <div id={videoOverLayId} className={styles.videoOverlay}></div>
          <div className={styles.videoContainer}>
            <video
              ref={(v) => {
                videoPlayer = v;
              }}
              id={videoId}
              className={[styles.video, styles.videoHidden].join(" ")}
              onEnded={onVideoEnd}
              playsInline
              src={accessory.video}
            ></video>
          </div>
          <div className={styles.bottom}>
            <h2 className={styles.name}>{accessory.title}</h2>
            {accessory.subtitle ? (
              <h3 className={styles.subtitle}>{accessory.subtitle}</h3>
            ) : null}
          </div>
          <div
            className={[
              styles.playButtonContainer,
              playZ ? styles.playZ : "",
            ].join(" ")}
          >
            <TButton onClick={playVideo} type={BUTTON_TYPES.action_play} />
          </div>
        </div>
      </TouchWraper>
    </div>
  );
};

export default AccessoryCard;
