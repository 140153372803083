import React, { useContext, useEffect, useState } from "react";
import { Recipe } from "../../model";
import RecipeCard from "../../components/recipe_card";
import styles from "./recipe_list.module.css";
import { RecipeContext } from "../../contexts/recipe";
import { NavigationContext } from "../../contexts/navigation";
import "./animation.css";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { useParams } from "react-router";
import { AccessoryContext } from "../../contexts/accessory";
import EmptyCard from "../../components/empty_card";

interface Params {
  category_id?: string;
}

const RecipeList = ({ history }: RouteComponentProps) => {
  const { setAccessoryState } = useContext(AccessoryContext);
  const { recipesByCategory } = useContext(RecipeContext);
  const { setHeaderState } = useContext(NavigationContext);

  const { category_id } = useParams<Params>();
  const categoryId = category_id ? Number(category_id) : undefined;

  const [recipes, setRecipes] = useState<Recipe[]>([]);

  useEffect(() => {
    if (!categoryId || recipes.length > 0) return;

    const category = recipesByCategory.find((rbc) => rbc.id === categoryId);
    if (!category) return;

    setRecipes(category.recipes);

    setHeaderState({
      title: category ? category.name : "TAKK för maten",
    });
  }, [categoryId, recipesByCategory, recipes, setHeaderState]);

  useEffect(() => {
    setAccessoryState({});
  }, [setAccessoryState]);

  const cardClicked = (recipeId: number) => {
    history.push(`/recipe-overview/${recipeId}`);
  };

  const getEmptyCards = () => {
    let l = recipes.length;
    const amount = Math.floor((window.innerWidth - 50) / 300);
    const emptyCards = [];
    while (l % amount !== 0) {
      emptyCards.push(<EmptyCard key={l} />);
      l++;
    }
    return emptyCards;
  };

  return (
    <div className={styles.container}>
      <div className={styles.list}>
        {recipes.map((recipe) => (
          <RecipeCard
            key={recipe.id}
            data={{
              id: recipe.id,
              name: recipe.name,
              video: recipe.video,
              image: recipe.image,
            }}
            onCardClicked={() => cardClicked(recipe.id)}
          />
        ))}
        {getEmptyCards()}
      </div>
    </div>
  );
};

export default withRouter(RecipeList);
