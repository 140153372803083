import React, { useState, useEffect } from "react";
import { Recipe, RecipesByCategory } from "./model";
import Navigation from "./navigation";
import { RecipeContext } from "./contexts/recipe";
import { AccessoryContext, AccessoryState } from "./contexts/accessory";
import { ShoppingListContext } from "./contexts/shopping_list";
import Loader from "./components/loader";
import getData from "./model/data_handler";

const AppContainer = () => {
  const [state, setState] = useState({
    recipes: [] as Recipe[],
    recipesByCategory: [] as RecipesByCategory[],
  });
  const [accessory_state, setAccessoryState] = useState<AccessoryState>({});
  const [unchecked, setUnchecked] = useState<Array<number>>(() => []);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchRecipes = async () => {
      const recipes = (await getData()) as Recipe[];

      const recipesByCategory = recipes
        .reduce((acc: RecipesByCategory[], recipe) => {
          recipe.categories.forEach((cat) => {
            const index = acc.findIndex((rbc) => rbc.id === cat.id);
            if (index !== -1) {
              acc[index].recipes.push(recipe);
            } else {
              acc.push({
                ...cat,
                video: recipe.video,
                recipes: [recipe],
              });
            }
          });
          return acc;
        }, [])
        .sort((a, b) => a.position - b.position);

      setState({ recipes, recipesByCategory });
      setIsLoading(false);
    };
    if (window.location.hash.includes("integritetspolicy")) {
      setIsLoading(false);
      return;
    }
    if (window.location.pathname === "/") {
      setIsLoading(false);
    }
    fetchRecipes();
  }, []);

  return (
    <RecipeContext.Provider value={state}>
      <AccessoryContext.Provider value={{ accessory_state, setAccessoryState }}>
        <ShoppingListContext.Provider value={{ unchecked, setUnchecked }}>
          {isLoading ? <Loader /> : <Navigation />}
        </ShoppingListContext.Provider>
      </AccessoryContext.Provider>
    </RecipeContext.Provider>
  );
};

export default AppContainer;
