import SoupImage from "../assets/recipe_categories/soppa_1280x720.jpg";
import VegoImage from "../assets/recipe_categories/vego_1280x720.jpg";
import SalladImage from "../assets/recipe_categories/sallad_1280x720.jpg";
import MeatImage from "../assets/recipe_categories/kott_1280x720.jpg";
import FishImage from "../assets/recipe_categories/fisk_1280x720.jpg";
import FridayImage from "../assets/recipe_categories/fredagsmys_1280x720.jpg";
import PastaImage from "../assets/recipe_categories/pasta_1280x720.jpg";
import ChickenImage from "../assets/recipe_categories/kyckling_1280x720.jpg";
import SausageImage from "../assets/recipe_categories/korv_1280x720.jpg";

import SoupVideo from "../assets/recipe_categories_video/soppa.mp4";
import VegoVideo from "../assets/recipe_categories_video/vego.mp4";
import SalladVideo from "../assets/recipe_categories_video/sallad.mp4";
import MeatVideo from "../assets/recipe_categories_video/kott.mp4";
import FishVideo from "../assets/recipe_categories_video/fisk.mp4";
import FridayVideo from "../assets/recipe_categories_video/fredagsmys.mp4";
import PastaVideo from "../assets/recipe_categories_video/pasta.mp4";
import ChickenVideo from "../assets/recipe_categories_video/kyckling.mp4";
import SausageVideo from "../assets/recipe_categories_video/korv.mp4";

export const getRecipeCategoryImage = (categoryId: number) => {
  switch (categoryId) {
    case 3:
      return SoupImage;
    case 4:
      return VegoImage;
    case 5:
      return SalladImage;
    case 6:
      return MeatImage;
    case 7:
      return FishImage;
    case 8:
      return FridayImage;
    case 9:
      return PastaImage;
    case 10:
      return ChickenImage;
    case 11:
      return SausageImage;
    default:
      return undefined;
  }
};

export const getRecipeCategoryVideo = (categoryId: number) => {
  switch (categoryId) {
    case 3:
      return SoupVideo;
    case 4:
      return VegoVideo;
    case 5:
      return SalladVideo;
    case 6:
      return MeatVideo;
    case 7:
      return FishVideo;
    case 8:
      return FridayVideo;
    case 9:
      return PastaVideo;
    case 10:
      return ChickenVideo;
    case 11:
      return SausageVideo;
    default:
      return undefined;
  }
};
