import React from "react";
import styles from "./t_button.module.css";
import TouchWraper from "../touch_wraper";
import arrowRight1 from "../../../assets/new_ui/stegframliten@x1.png";
import arrowRight2 from "../../../assets/new_ui/stegframliten@x2.png";
import arrowRight3 from "../../../assets/new_ui/stegframliten@x3.png";
import arrowLeft1 from "../../../assets/new_ui/stegbakliten@x1.png";
import arrowLeft2 from "../../../assets/new_ui/stegbakliten@x2.png";
import arrowLeft3 from "../../../assets/new_ui/stegbakliten@x3.png";
import tips1 from "../../../assets/new_ui/tipsliten@x1.png";
import tips2 from "../../../assets/new_ui/tipsliten@x2.png";
import tips3 from "../../../assets/new_ui/tipsliten@x3.png";
import rosa1 from "../../../assets/new_ui/rosaliten.png";
import rosa2 from "../../../assets/new_ui/rosaliten@2x.png";
import rosa3 from "../../../assets/new_ui/rosaliten@3x.png";
import play1 from "../../../assets/new_ui/playfilmliten@x1.png";
import play2 from "../../../assets/new_ui/playfilmliten@x2.png";
import play3 from "../../../assets/new_ui/playfilmliten@x3.png";
import shop1 from "../../../assets/new_ui/handlalistamindre@x1.png";
import shop2 from "../../../assets/new_ui/handlalistamindre@x2.png";
import shop3 from "../../../assets/new_ui/handlalistamindre@x3.png";
import goodWork1 from "../../../assets/new_ui/brajobbat@x1.png";
import goodWork2 from "../../../assets/new_ui/brajobbat@x2.png";
import pers1 from "../../../assets/new_ui/2pers-white.png";
import pers2 from "../../../assets/new_ui/2pers-white@2x.png";
import pers3 from "../../../assets/new_ui/2pers-white@3x.png";

import share1 from "../../../assets/new_ui/share_white.png";
import share2 from "../../../assets/new_ui/share_white@2x.png";
import share3 from "../../../assets/new_ui/share_white@3x.png";

export enum BUTTON_TYPES {
  round = "ROUND",
  standard = "STANDARD",
  action_forward = "action_forward",
  action_back = "action_back",
  action_info = "action_info",
  action_play = "action_play",
  action_shop = "action_shop",
  action_share = "action_share",
  action_done = "action_done",
  button = "button",
  action_2_pers = "action_2_pers",
  action_forward_double = "action_forward_double",
}

interface ITButton {
  onClick: () => void;
  className?: string;
  type?: BUTTON_TYPES;
  id?: string;
}

const TButton: React.SFC<ITButton> = (props) => {
  const getStyle = () => {
    switch (props.type) {
      case BUTTON_TYPES.round:
        return styles.round;
      case BUTTON_TYPES.standard:
        return styles.standard;
      case BUTTON_TYPES.button:
        return styles.notype;
      default:
        return "";
    }
  };

  if (!props.type && !props.className) {
    return <TouchWraper onClick={props.onClick}>{props.children}</TouchWraper>;
  }
  const getButton = () => {
    switch (props.type) {
      case BUTTON_TYPES.action_forward:
        return (
          <div
            className={[
              styles.large_round,
              styles.green,
              styles.elevation,
              props.className,
            ].join(" ")}
          >
            <img
              className={styles.image}
              src={arrowRight2}
              srcSet={`${arrowRight1} 1x, ${arrowRight2} 2x, ${arrowRight3} 3x`}
              alt="Nästa"
            />
          </div>
        );
      case BUTTON_TYPES.action_forward_double:
        return (
          <div
            className={[
              styles.large_round,
              styles.green,
              styles.elevation,
              props.className,
            ].join(" ")}
          >
            <img
              className={styles.image}
              style={{ marginRight: -46 }}
              src={arrowRight2}
              srcSet={`${arrowRight1} 1x, ${arrowRight2} 2x, ${arrowRight3} 3x`}
              alt="Nästa"
            />
            <img
              className={styles.image}
              src={arrowRight2}
              srcSet={`${arrowRight1} 1x, ${arrowRight2} 2x, ${arrowRight3} 3x`}
              alt="Nästa"
            />
          </div>
        );
      case BUTTON_TYPES.action_back:
        return (
          <div
            className={[
              styles.large_round,
              styles.green,
              styles.elevation,
              props.className,
            ].join(" ")}
          >
            <img
              className={styles.image}
              src={arrowLeft2}
              srcSet={`${arrowLeft1} 1x, ${arrowLeft2} 2x, ${arrowLeft3} 3x`}
              alt="Bakåt"
            />
          </div>
        );
      case BUTTON_TYPES.action_info:
        return (
          <div
            className={[
              styles.large_round,
              styles.yellow,
              styles.elevation,
              props.className,
            ].join(" ")}
          >
            {/* <img src={arrowRight} alt="Nästa" /> */}
            <img
              className={styles.image}
              src={tips2}
              srcSet={`${tips1} 1x, ${tips2} 2x, ${tips3} 3x`}
              alt="Information"
            />
          </div>
        );
      case BUTTON_TYPES.action_2_pers:
        return (
          <div
            className={[
              styles.large_round,
              styles.green,
              styles.elevation,
              props.className,
            ].join(" ")}
          >
            <img
              className={styles.image}
              src={pers2}
              srcSet={`${pers1} 1x, ${pers2} 2x, ${pers3} 3x`}
              alt="Information"
            />
          </div>
        );
      case BUTTON_TYPES.action_play:
        return (
          <div
            id={props.id}
            className={[
              styles.large_round,
              styles.elevation,
              styles.play,
              props.className,
            ].join(" ")}
          >
            <img
              className={styles.rosa}
              src={rosa2}
              srcSet={`${rosa1} 1x, ${rosa2} 2x, ${rosa3} 3x`}
              alt="Spela"
            />
            <img
              className={styles.playRosa}
              src={play2}
              srcSet={`${play1} 1x, ${play2} 2x, ${play3} 3x`}
              alt="Spela"
            />
          </div>
        );
      case BUTTON_TYPES.action_shop:
        return (
          <div
            className={[
              styles.large_round,
              styles.orange,
              styles.elevation,
              props.className,
            ].join(" ")}
          >
            <img
              className={styles.iconButton}
              src={shop2}
              srcSet={`${shop1} 1x, ${shop2} 2x, ${shop3} 3x`}
              alt="Handlingslista"
            />
          </div>
        );
      case BUTTON_TYPES.action_share:
        return (
          <div
            className={[
              styles.large_round,
              styles.blue,
              styles.elevation,
              props.className,
            ].join(" ")}
          >
            <img
              className={styles.iconButton}
              src={share2}
              srcSet={`${share1} 1x, ${share2} 2x, ${share3} 3x`}
              alt="Dela"
            />
          </div>
        );
      case BUTTON_TYPES.action_done:
        return (
          <div className={[props.className].join(" ")}>
            <img
              src={goodWork2}
              className={styles.done}
              srcSet={`${goodWork1} 1x, ${goodWork2} 2x`}
              alt="tumme upp"
            />
          </div>
        );
      case BUTTON_TYPES.button:
        return (
          <div className={[getStyle(), props.className].join(" ")}>
            {props.children}
          </div>
        );
      default:
        return (
          <div className={[getStyle(), props.className].join(" ")}>
            {props.children}
          </div>
        );
    }
  };

  return <TouchWraper onClick={props.onClick}>{getButton()}</TouchWraper>;
};

export default TButton;
