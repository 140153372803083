import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./header.module.css";
import { NavigationContext } from "../../contexts/navigation";
import back1 from "../../../assets/new_ui/stegbakliten@x1.png";
import back2 from "../../../assets/new_ui/stegbakliten@x2.png";
import back3 from "../../../assets/new_ui/stegbakliten@x3.png";
import info1 from "../../../assets/new_ui/info.png";
import info2 from "../../../assets/new_ui/info@2x.png";
import info3 from "../../../assets/new_ui/info@3x.png";
import header_logo from "../../../assets/img/header_logo_1x.png";
import header_logo_2x from "../../../assets/img/header_logo_2x.png";
import header_logo_3x from "../../../assets/img/header_logo_3x.png";
import { useHistory } from "react-router";
import TouchWraper from "../touch_wraper";
import CloseButton from "../close_button";
import TButton, { BUTTON_TYPES } from "../t_button/t_button";

interface props {
  left?: JSX.Element;
  title?: string;
  right?: JSX.Element;
  infoData?: {
    videoUrl?: string;
    text?: string;
  };
}

const Header = ({ left, title, right, infoData }: props) => {
  const { header_state } = useContext(NavigationContext);
  const [hasBackButton, setHasBackButton] = useState(true);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [isInfoVideoPlaying, setIsInfoVideoPlaying] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    setHasBackButton(
      header_state.hasBackButton || header_state.hasBackButton === undefined
    );
  }, [header_state]);

  const history = useHistory();

  const onBackClicked = () => {
    history.goBack();
  };

  const handleInfoPress = () => {
    setShowInfoModal((prevState) => !prevState);
  };
  const handleCloseInfoModal = () => {
    setShowInfoModal(false);
  };
  const handlePlayInfoVideo = () => {
    if (!videoRef.current) return;
    videoRef.current.currentTime = 0;
    videoRef.current.play();
    setIsInfoVideoPlaying(true);
  };
  const handleInfoVideoEnded = () => {
    if (!videoRef.current) return;
    setIsInfoVideoPlaying(false);
    videoRef.current.currentTime = 0;
  };

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        {left || (
          <div>
            {hasBackButton && (
              <TouchWraper
                ariaLabel="Tillbaka"
                tabIndex={1}
                onClick={onBackClicked}
              >
                <img
                  className={styles.menu}
                  src={back1}
                  srcSet={`${back1} 1x, ${back2} 2x, ${back3} 3x`}
                  alt="back-icon"
                />
              </TouchWraper>
            )}
          </div>
        )}
      </div>
      <div className={styles.center}>
        <h1 className={styles.title}>{title || header_state.title}</h1>
      </div>
      <div className={styles.right}>
        {infoData && (
          <div className={styles.containerInfo}>
            <img
              className={styles.imgInfo}
              src={info1}
              srcSet={`${info1} 1x, ${info2} 2x, ${info3} 3x`}
              alt="info"
              onClick={handleInfoPress}
            />
          </div>
        )}
        {right || (
          <img
            className={styles.icon}
            src={header_logo}
            srcSet={`${header_logo} 1x, ${header_logo_2x} 2x, ${header_logo_3x} 3x`}
            alt="Logotyp"
          />
        )}
      </div>
      {showInfoModal && (
        <div className={styles.infoModal}>
          <div className={styles.infoArrow} />
          <div className={styles.infoExitBtn}>
            <CloseButton onClick={handleCloseInfoModal} />
          </div>
          {infoData && !!infoData.text && (
            <>
              {infoData.text.split("\n").map((textSplit) => (
                <p className={styles.infoText} key={textSplit}>
                  {textSplit}
                </p>
              ))}
            </>
          )}
          {infoData && infoData.videoUrl && (
            <>
              <div className={styles.containerVideo}>
                <TButton
                  onClick={handlePlayInfoVideo}
                  type={BUTTON_TYPES.action_play}
                />
              </div>
              <video
                ref={videoRef}
                src={infoData.videoUrl}
                className={[
                  styles.infoVideo,
                  isInfoVideoPlaying ? styles.infoVideoShow : undefined,
                ].join(" ")}
                playsInline
                onEnded={handleInfoVideoEnded}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Header;
