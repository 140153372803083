import React from "react";
import { useParams } from "react-router-dom";

interface Params {
  app_name?: string;
}

export const PrivacyPolicyPage = () => {
  const { app_name } = useParams<Params>();

  return (
    <div
      style={{
        backgroundColor: "#fff",
        padding: "8%",
        height: "100%",
        overflowX: "auto",
      }}
    >
      <h2>INTEGRITETSPOLICY</h2>
      <p>
        <b>
          {app_name
            ? app_name.replace(/_/g, " ").toUpperCase()
            : "TAKK FÖR MATEN"}
        </b>{" "}
        samlar ej in och sparar heller ingen personlig information om
        användaren.
      </p>
      <br />
      <h4 style={{ fontWeight: "bold" }}>Datainspektionen</h4>
      <p style={{ margin: 0, marginBottom: 4 }}>
        Datainspektionen är tillsynsmyndighet för behandling av personuppgifter.
      </p>
      <p style={{ marginTop: 0 }}>
        Kontaktuppgifter till Datainspektionen finner du nedan:
      </p>
      <p style={{ margin: 0, marginBottom: 4 }}>Datainspektionen</p>
      <p style={{ margin: 0, marginBottom: 4 }}>Box 8114</p>
      <p style={{ margin: 0, marginBottom: 4 }}>104 20</p>
      <p style={{ margin: 0, marginBottom: 4 }}>Stockholm</p>
      <p style={{ margin: 0, marginBottom: 4 }}>Telefon: 08-657 61 00</p>
      <p style={{ margin: 0, marginBottom: 4 }}>
        E-post: datainspektionen@datainspektionen.se
      </p>
    </div>
  );
};
