import React, { useEffect, useState, useRef, CSSProperties } from "react";
import styles from "./recipe_tip.module.css";
import CloseButton from "../close_button";
import TButton from "../t_button";
import { BUTTON_TYPES } from "../t_button/t_button";

interface props {
  text: string;
  video: string;
  onClose: () => void;
  arrowStyle?: CSSProperties;
}

const RecipeTip = (props: props) => {
  const id = "recipe-tip-container-id";
  const wraperId = "recipe-tip-wraper-id";
  const onClose = () => {
    const container = document.getElementById(id);
    const wraper = document.getElementById(wraperId);
    if (container) container.classList.toggle(styles.hide);
    if (wraper) wraper.classList.toggle(styles.hideWraper);
    setTimeout(props.onClose, 220);
  };

  const [videoVisible, setVideoVisible] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener("ended", (_e) =>
        setVideoVisible(false)
      );
    }
  }, [videoRef]);

  const toggleVideo = () => {
    // e.preventDefault()
    if (videoRef.current) {
      if (videoVisible) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
        setVideoVisible(false);
      } else {
        videoRef.current.play();
        setVideoVisible(true);
      }
    }
  };
  return (
    <div id={wraperId} className={styles.wraper}>
      <div id={id} className={styles.container}>
        <div className={styles.close}>
          <CloseButton onClick={onClose} />
        </div>
        <p>{props.text}</p>
        <div
          className={[
            styles.videoWrapper,
            videoVisible ? styles["video-wrapper-visible"] : "",
          ].join(" ")}
        >
          <video
            tabIndex={-1}
            aria-label="Instruktionsvideo"
            playsInline
            ref={videoRef}
            className={[
              styles.video,
              videoVisible ? styles["video-visible"] : "",
            ].join(" ")}
            src={props.video}
          ></video>
        </div>
        <div
          className={[
            styles.playbutton_container,
            videoVisible ? styles.playbutton_container_hidden : "",
          ].join(" ")}
        >
          <TButton type={BUTTON_TYPES.action_play} onClick={toggleVideo} />
        </div>
        <div className={styles.arrow} style={props.arrowStyle} />
        <div className={styles.hider} />
      </div>
    </div>
  );
};

export default RecipeTip;
