import React, { Component, createRef } from "react";
import DragablePlayer from "./dragable_player/dragable_player";
import PlayerControls from "./player_controls";
import styles from "./recipe_player.module.css";
import "./recipe_player.css";
import { Step } from "../../model";
import StepIcons from "../step_icons";
import PlayButton from "../play_button";
import { PlayButtonTypes } from "../play_button/play_button";
import TBackground from "../t_background";
import { isMobileApp } from "../../util/deviceHelpers";
import TButton, { BUTTON_TYPES } from "../t_button/t_button";

interface IRecipePlayer {
  landscape: boolean;
  onNextClick: () => void;
  onPreviousClick: () => void;
  onCancelClick: () => void;
  step: Step;
  name: string;
  stepNumber: number;
  totalSteps: number;
}

class RecipePlayer extends Component<IRecipePlayer> {
  signPlayer = createRef<DragablePlayer>();
  foodPlayer = createRef<HTMLVideoElement>();

  state = {
    showControls: false,
    isPlaying: false,
    signPlayerDidEnd: false,
    signPlayerIsPlaying: false,
    showSignOverlay: false,
    overlayHeight: "100vh",
    foodPlayerState: {
      paused: true,
      ended: false,
    },
    showTooltip: false,
    didShowTooltip: false,
    isInitial: true,
    isUpdatingStep: false,
    isSignReplay: false,
    showFinalView: false,
  };

  onResize = () => {
    this.setOverlayHeight();
    this.setShowTooltip();
  };

  componentDidMount() {
    this.setOverlayHeight();
    this.setShowTooltip();

    window.addEventListener("resize", this.onResize);

    if (this.foodPlayer.current) {
      const { paused, ended } = this.state.foodPlayerState;
      this.foodPlayer.current.addEventListener("ended", () =>
        this.setState({
          foodPlayerState: {
            paused: paused,
            ended: true,
          },
          isPlaying: false,
          signPlayerDidEnd: false,
          showControls: true,
        })
      );
      this.foodPlayer.current.addEventListener("pause", () =>
        this.setState({
          foodPlayerState: { paused: true, ended: ended },
          isPlaying: false,
        })
      );
    }
  }

  componentDidUpdate(newProps: IRecipePlayer) {
    if (newProps.step.id !== this.props.step.id) {
      this.setState({
        showControls: false,
        isPlaying: false,
        signPlayerDidEnd: false,
        signPlayerIsPlaying: false,
        showSignOverlay: false,
        foodPlayerState: {
          paused: true,
          ended: false,
        },
        showTooltip: false,
        didShowTooltip: false,
        isInitial: true,
      });
    }
  }

  setShowTooltip() {
    if (!this.props.landscape && !this.state.didShowTooltip) {
      this.setState({ showTooltip: true, didShowTooltip: true });
    } else {
      this.setState({ showTooltip: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  setOverlayHeight() {
    const p = document.getElementById("recipe-player-food-player-id");
    const res = p ? p.offsetHeight : "";
    this.setState({ overlayHeight: res });
  }

  playClicked = () => {
    this.setState({ isInitial: false });
    const { paused } = this.state.foodPlayerState;
    if (paused) {
      if (!this.state.signPlayerIsPlaying) this.playFoodPlayer();
      if (!this.state.signPlayerDidEnd) this.playSignPlayer();
    } else {
      this.pauseSignPlayer();
      this.pauseFoodPlayer();
    }
  };

  pauseFoodPlayer = () => {
    if (this.foodPlayer.current) {
      this.setState({
        btnText: "Play",
        isPlaying: false,
        foodPlayerState: {
          paused: true,
          ended: this.state.foodPlayerState.ended,
        },
      });
      this.foodPlayer.current.pause();
    }
  };

  playFoodPlayer = () => {
    if (this.foodPlayer.current) {
      this.setState({
        btnText: "Pause",
        isPlaying: true,
        foodPlayerState: {
          paused: false,
          ended: this.state.foodPlayerState.ended,
        },
      });
      this.foodPlayer.current.play();
    }
  };

  playSignPlayer = () => {
    if (this.signPlayer.current) {
      this.setState({
        signPlayerDidEnd: false,
        signPlayerIsPlaying: true,
        showSignOverlay: true,
      });
      this.signPlayer.current.play();
    }
  };

  pauseSignPlayer = () => {
    if (this.signPlayer.current) {
      this.signPlayer.current.pause();
    }
  };

  replayClicked = () => {
    if (this.foodPlayer.current && this.signPlayer.current) {
      this.foodPlayer.current.currentTime = 0;
      this.signPlayer.current.currentTime = 0;
      this.playFoodPlayer();
    }
  };

  onSignPlayerEnd = () => {
    const { stepNumber, totalSteps } = this.props;
    this.setState(
      {
        signPlayerDidEnd: true,
        signPlayerIsPlaying: false,
        showSignOverlay: false,
      },
      () => {
        if (this.state.isSignReplay) {
          this.setState({ isSignReplay: false, showControls: true });
          return;
        }
        if (stepNumber === totalSteps || stepNumber === 1) {
          setTimeout(() => {
            this.setState({
              isPlaying: false,
              showControls: true,
              foodPlayerState: {
                ended: true,
              },
            });
          }, 500);
        } else {
          this.playClicked();
        }
      }
    );
  };

  onSignPlayerIsPlaying = () => {
    this.setState({
      signPlayerIsPlaying: true,
      signPlayerDidEnd: false,
      showSignOverlay: true,
    });
    if (this.state.isPlaying) {
      this.pauseFoodPlayer();
    }
    this.setState({ isPlaying: true });
  };

  toogleControls = () => {
    this.setState({ showControls: true });
  };

  onCloseControls = () => {
    this.setState({ showControls: false });
  };

  onCloseTooltip = () => {
    this.setState({ showTooltip: false });
  };

  handleStepClick = (isNextStep = true) => {
    if (isMobileApp()) {
      // Trick to force video el to update
      // and display next/prev poster on mobile/tablet devices
      this.setState({
        isUpdatingStep: true,
      });
      this.setState({
        isUpdatingStep: false,
      });
    }

    if (isNextStep) {
      this.props.onNextClick();
      return;
    }
    this.props.onPreviousClick();
  };

  handleSignReplay = () => {
    if (!this.signPlayer.current) return;
    this.setState({ isSignReplay: true });
    this.signPlayer.current.play();
  };

  render() {
    const {
      isUpdatingStep,
      isPlaying,
      overlayHeight,
      foodPlayerState,
      showControls,
      showFinalView,
    } = this.state;
    const { step, landscape, name, totalSteps, stepNumber } = this.props;

    return (
      <TBackground
        disable={landscape}
        className={[
          styles.container,
          landscape ? styles.landscape : styles.porttrait,
        ].join(" ")}
      >
        {showFinalView && (
          <div className={styles.done_container}>
            <div
              style={{
                pointerEvents: "none",
              }}
            >
              <TButton
                className={isMobileApp() ? styles.done_portrait : ""}
                type={BUTTON_TYPES.action_done}
                onClick={() => {}}
              />
            </div>
            <div className={styles.done_button_container}>
              <TButton
                type={BUTTON_TYPES.action_back}
                onClick={() => this.setState({ showFinalView: false })}
              />
              <TButton
                type={BUTTON_TYPES.action_forward_double}
                onClick={this.props.onCancelClick}
              />
            </div>
          </div>
        )}
        <video
          id="recipe-player-food-player-id"
          ref={this.foodPlayer}
          poster={step.poster}
          className={[
            "recipe_player_food_player",
            styles.food_player,
            landscape ? styles.food_player_landscape : "",
          ].join(" ")}
          playsInline={true}
          muted
          src={isUpdatingStep ? "" : step.video}
        />

        <div
          style={{
            height: landscape ? window.innerHeight : overlayHeight,
            marginTop: landscape ? 0 : 60,
          }}
          className={styles.overlay}
          onClick={this.toogleControls}
        >
          {!landscape &&
            this.state.isInitial &&
            !showControls &&
            !(stepNumber === totalSteps) && (
              <PlayButton
                type={PlayButtonTypes.play}
                onClick={this.playClicked}
              />
            )}
        </div>

        {landscape &&
          this.state.isInitial &&
          !showControls &&
          !(stepNumber === totalSteps) && (
            <div className={styles.init_play_button}>
              <PlayButton
                type={PlayButtonTypes.play}
                onClick={this.playClicked}
              />
            </div>
          )}
        <div
          className={[
            landscape
              ? styles.instruction_container_lanscape
              : styles.instruction_container_portrait,
          ].join(" ")}
        >
          {landscape ? <div className={styles.instruction_filler} /> : null}
          <div className={styles.text_container}>
            <p
              className={[
                styles.instruction_text,
                landscape
                  ? styles.instruction_text_landscape
                  : styles.instruction_text_portrait,
              ].join(" ")}
            >
              {step.step_text}
            </p>
          </div>

          <div className={landscape ? styles.icon_container : ""}>
            <StepIcons ingredients={step.ingredients} />
          </div>
        </div>
        <DragablePlayer
          ref={this.signPlayer}
          didEnd={this.onSignPlayerEnd}
          poster={step.sign_poster}
          src={isUpdatingStep ? "" : step.sign_video}
          landscape={landscape}
          onIsPlaying={this.onSignPlayerIsPlaying}
          isSignPlayVisible={
            stepNumber === totalSteps && !this.state.signPlayerIsPlaying
          }
        />
        {(!landscape || this.state.showControls) && (
          <PlayerControls
            landscape={landscape}
            name={name}
            onCloseDone={this.onCloseControls}
            onPlayClick={this.playClicked}
            isPlaying={isPlaying}
            onReplayClick={this.replayClicked}
            didEnd={foodPlayerState.ended}
            stepNumber={stepNumber}
            totalSteps={totalSteps}
            onNextClick={this.handleStepClick}
            onPreviousClick={() => this.handleStepClick(false)}
            onCancelClick={this.props.onCancelClick}
            showControls={this.state.showControls}
            onSignReplayClick={this.handleSignReplay}
            onDoneClick={() => this.setState({ showFinalView: true })}
          />
        )}
      </TBackground>
    );
  }
}

export default RecipePlayer;
