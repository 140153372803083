import React, { useEffect, useReducer, useContext, useState } from "react";
import { Accessory } from "../../model";
import styles from "./accessory_list.module.css";
import "./animation.css";
import AccessoryCard from "../accessory_card";
import { AccessoryContext } from "../../contexts/accessory";
import EmptyCard from "../empty_card";

interface Props {
  accessories: Accessory[];
  onAllCheckedChange: (allChecked: boolean) => void;
  onCheckedChange?: (checked: number[]) => void;
  recipeId: number;
  routeKey: string;
  isTool?: boolean;
  renderEmptyCards?: boolean;
}

type CheckAction =
  | { type: "check"; id: number }
  | { type: "init"; num: number }
  | { type: "check_many"; ids: number[] };
interface CheckState {
  ids: number[];
  all: boolean;
  num: number;
}
function checkedReducer(state: CheckState, action: CheckAction): CheckState {
  switch (action.type) {
    case "check":
      const ids = state.ids.includes(action.id)
        ? state.ids.filter((id) => id !== action.id)
        : [...state.ids, action.id];
      return {
        ids: ids,
        all: ids.length === state.num,
        num: state.num,
      };

    case "check_many":
      return {
        ids: [...action.ids],
        all: action.ids.length === state.num,
        num: state.num,
      };
    case "init":
      return {
        ids: state.ids,
        all: state.ids.length === action.num,
        num: action.num,
      };
  }
}

const AccessoryList = ({
  accessories,
  onAllCheckedChange,
  onCheckedChange,
  recipeId,
  routeKey,
  isTool,
  renderEmptyCards = true,
}: Props) => {
  const [checked, dispatchCheck] = useReducer(checkedReducer, {
    ids: [],
    all: false,
    num: 0,
  });
  const { accessory_state, setAccessoryState } = useContext(AccessoryContext);
  const [isFirstTimeCheck, setIsFirstTimeCheck] = useState(true);

  useEffect(() => {
    if (
      isFirstTimeCheck &&
      accessory_state &&
      routeKey in accessory_state &&
      recipeId in accessory_state[routeKey].recipes
    ) {
      setIsFirstTimeCheck(false);
      dispatchCheck({
        type: "check_many",
        ids: accessory_state[routeKey].recipes[recipeId].ids,
      });
    }
  }, [routeKey, recipeId, accessory_state, isFirstTimeCheck]);

  useEffect(() => {
    setAccessoryState((prevState) => ({
      ...prevState,
      [routeKey]: {
        recipes: {
          [recipeId]: checked,
        },
      },
    }));
  }, [checked, recipeId, routeKey, setAccessoryState]);

  useEffect(() => {
    dispatchCheck({ type: "init", num: accessories.length });
  }, [accessories]);

  useEffect(() => {
    if (onCheckedChange) onCheckedChange(checked.ids);
    onAllCheckedChange(checked.all);
  }, [checked, onAllCheckedChange, onCheckedChange]);

  const cardClicked = (accessory: Accessory) => {
    dispatchCheck({ type: "check", id: accessory.id });
  };

  const getEmptyCards = () => {
    let l = accessories.length;
    const amount = Math.floor((window.innerWidth - 50) / 300);
    const emptyCards = [];
    while (l % amount !== 0) {
      emptyCards.push(<EmptyCard key={l} />);
      l++;
    }
    return emptyCards;
  };

  return (
    <div className={styles.container}>
      {accessories && (
        <div className={styles.list}>
          {accessories.map((accessory) => (
            <AccessoryCard
              tool={isTool}
              key={accessory.id}
              accessory={accessory}
              onClick={cardClicked}
              checked={checked.ids.includes(accessory.id)}
            />
          ))}
          {renderEmptyCards && getEmptyCards()}
        </div>
      )}
    </div>
  );
};

export default AccessoryList;
